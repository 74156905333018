import React from 'react'
import ArchiveCardWrapper from '../../../components/ArchiveCardWrapper/ArchiveCardWrapper'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import SEO from '../../../components/seo'
import { Header1 } from '../../../components/Headers/Headers'
import MonumentCardRocznik from '../../../components/MonumentCard/MonumentCardRocznik'
import MonumentCardAsset from '../../../components/MonumentCard/MonumentCardAsset'
import MonumentCardDworzecPKPDebe from '../../../monuments/debe-wielkie/MonumentCardDworzecPKPDebe'
import MonumentCardDworOlesinDebe from '../../../monuments/debe-wielkie/MonumentCardDworOlesinDebe'

const DebeWielkieArchive = () => {
  return (
    <ArchiveWrapper>
      <SEO title="Materiały dotyczące gminy Dębe Wielkie" />
      <ArchiveCardWrapper>
        <Header1>Materiały dotyczące gminy Dębe Wielkie</Header1>
        <MonumentCardDworzecPKPDebe />
        <MonumentCardDworOlesinDebe />
        <MonumentCardRocznik
          title={'Karczmy wzdłuż traktu brzeskiego: ich znaczenie i funkcje'}
          author={'Karolina Wanda Gańko'}
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/rocznik/DebeWielkieRoczniktom262018KarolinaWandaGankoKarczmywzdluztraktubrzeskiegoichznaczenieifunkcjeszkic.pdf'
          }
        />
        <MonumentCardAsset
          title={
            'Bohaterska postawa chłopa z Dębego Wielkiego Ludwika Walesiaka, podczas powstania styczniowego 1863 r. Przedruk Gość Świąteczny 1913'
          }
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Bohaterska+postawa+ch%C5%82opa+z+De%CC%A8bego+Wielkiego+Ludwika+Walesiaka%2C+podczas+powstania+styczniowego+1863+r.+Przedruk+Gos%CC%81c%CC%81+S%CC%81wia%CC%A8teczny+1913.mp3'
          }
        />
      </ArchiveCardWrapper>
    </ArchiveWrapper>
  )
}

export default DebeWielkieArchive
