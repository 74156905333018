import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardDworOlesinDebe = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/debe-wielkie/dwor-olesin/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/debe-wielkie/olesin-zabytkowy-dwor'}
      title={'Olesin / zabytkowy dwór'}
    >
      Dobra Olesin Duży były własnością Jana i Eleonory Zaglenicznych do 1931 r.
      Małżonkowie, tytuł własności dóbr przepisali na swoją córkę Irenę
      Zagleniczną Maciejewiczową (Maciejewicz), zachowując sobie prawo
      dożywotniego użytkowania.
    </MonumentCard>
  )
}

export default MonumentCardDworOlesinDebe
