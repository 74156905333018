import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardDworzecPKPDebe = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/debe-wielkie/dworzec-pkp/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={
        '/gminy/debe-wielkie/aleksandrowka-zabytkowy-dworzec-pkp-debe-wielkie'
      }
      title={'Aleksandrówka / zabytkowy Dworzec PKP Dębe Wielkie'}
    >
      Linia kolejowa z Warszawy na wschód prowadząca swój szlak przez powiat
      Miński liczy sobie już 154 lata. Dębe Wielkie były na tej trasie
      przystankiem. W 1909 roku spłonął pierwotny budynek. Obecny datuje się na
      1912 rok.
    </MonumentCard>
  )
}

export default MonumentCardDworzecPKPDebe
